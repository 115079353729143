import React from "react"
import "./style.scss"

const LinkBankMessage = ({ error }) => {
  return (
    <div className="general-submit-message">
      <div className={`message ${error !== null ? `error` : ``}`}>
        {error !== null
          ? error
          : "By selecting “Link My Bank” you are agreeing to the Learn & Earn $1/month fee."}
      </div>
    </div>
  )
}
export default LinkBankMessage
