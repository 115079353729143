import React, { Component } from "react"

import SEO from "@components/seo"
import * as data from "@data/pledge"
import HeaderandText from "@components/header_with_text"
import Layout from "@components/layout"
import BackButton from "@components/back_button"
import LinkSuccess from "@components/_molecules/splash_link_success"
import LinkBankMessage from "@components/_atoms/link_bank_message"
import ProgressBar from "@components/_atoms/progress_bar_reg"

class LinkYourBank extends Component {
  constructor(props) {
    super(props)
    this.state = {
      bankSuccess: false,
      generalError: null,
    }
  }

  render() {
    const { linkYourBank } = data
    return (
      <Layout type="gray" pageClass="pledge">
        {!this.state.bankSuccess ? (
          <>
            <ProgressBar />
            <BackButton data={linkYourBank.backLink} />
            <SEO title={linkYourBank.title} />
            <HeaderandText data={linkYourBank.heading} />
            <div style={{ width: `78%`, margin: `3rem auto 5rem` }}>
              <img
                style={{ width: `100%` }}
                alt={linkYourBank.img.src}
                src={linkYourBank.img.src}
              />
            </div>
            <LinkBankMessage error={this.state.generalError} />
          </>
        ) : (
          <LinkSuccess />
        )}
      </Layout>
    )
  }
}

export default LinkYourBank
